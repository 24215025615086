<template>
  <CRow>
    <CCol sm="4" md="2" lg="2">
      <CCard color="default" class="text-center" body-wrapper>
        <CRow>
          <CCol sm="6" lg="8" class="text-left pr-0">
            <blockquote class="card-blockquote">
              <p class="text-muted">Resolved</p>
              <h4>{{ widgetCount.totalResolved }}</h4>
            </blockquote>
          </CCol>

          <!-- <CCol sm="6" lg="4" class="pt-3 text-right">
            <CIcon class="mb-2" name="cil-task" height="30" />
          </CCol> -->
        </CRow>
      </CCard>
    </CCol>

    <CCol sm="4" md="2" lg="2">
      <CCard color="default" class="text-center" body-wrapper>
        <CRow>
          <CCol sm="6" lg="8" class="text-left pr-0">
            <blockquote class="card-blockquote">
              <p class="text-muted">Overdue</p>
              <h4>0</h4>
            </blockquote>
          </CCol>

          <!-- <CCol sm="6" lg="4" class="pt-3 text-right">
            <CIcon class="mb-2" name="cil-task" height="30" />
          </CCol> -->
        </CRow>
      </CCard>
    </CCol>

    <CCol sm="4" md="2" lg="2">
      <CCard color="default" class="text-center" body-wrapper>
        <CRow>
          <CCol sm="6" lg="8" class="text-left pr-0">
            <blockquote class="card-blockquote">
              <p class="text-muted">Due Today</p>
              <h4>0</h4>
            </blockquote>
          </CCol>

          <!-- <CCol sm="6" lg="4" class="pt-3 text-right">
            <CIcon class="mb-2" name="cil-task" height="30" />
          </CCol> -->
        </CRow>
      </CCard>
    </CCol>

    <CCol sm="4" md="2" lg="2">
      <CCard color="default" class="text-center" body-wrapper>
        <CRow>
          <CCol sm="6" lg="8" class="text-left pr-0">
            <blockquote class="card-blockquote">
              <p class="text-muted">Pending</p>
              <h4>{{ widgetCount.totalPending }}</h4>
            </blockquote>
          </CCol>

          <!-- <CCol sm="6" lg="4" class="pt-3 text-right">
            <CIcon class="mb-2" name="cil-task" height="30" />
          </CCol> -->
        </CRow>
      </CCard>
    </CCol>

    <CCol sm="4" md="2" lg="2">
      <CCard color="default" class="text-center" body-wrapper>
        <CRow>
          <CCol sm="6" lg="8" class="text-left pr-0">
            <blockquote class="card-blockquote">
              <p class="text-muted">On hold</p>
              <h4>{{ widgetCount.totalOnHold }}</h4>
            </blockquote>
          </CCol>

          <!-- <CCol sm="6" lg="4" class="pt-3 text-right">
            <CIcon class="mb-2" name="cil-task" height="30" />
          </CCol> -->
        </CRow>
      </CCard>
    </CCol>

    <CCol sm="4" md="2" lg="2">
      <CCard color="default" class="text-center" body-wrapper>
        <CRow>
          <CCol sm="6" lg="8" class="text-left pr-0">
            <blockquote class="card-blockquote">
              <p class="text-muted">Unassigned</p>
              <h4>{{ widgetCount.totalUnAssigned }}</h4>
            </blockquote>
          </CCol>

          <!-- <CCol sm="6" lg="4" class="pt-3 text-right">
            <CIcon class="mb-2" name="cil-task" height="30" />
          </CCol> -->
        </CRow>
      </CCard>
    </CCol>

  </CRow>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "SupportWidgets",  
  computed: {
    ...mapGetters("CustomerSupport", ["widgetCount"]),
  },
  mounted() {
    this.$store.dispatch("CustomerSupport/getCounts");
  }  
};
</script>

<style scoped>
.card {
  border: none;
}
</style>

<style lang="scss" scoped>
.card-body {
  .row {
    div {
      blockquote {
        h4 {
          font-size: 22px;
          font-weight: 700;
        }
        p {
          font-size: 12px;
          font-weight: 700;
        }
      }
    }
  }
}
</style>


