<template>
  <CRow>
    <CCol>
      <div v-if="serverErrors.length">
        <CAlert v-model="showDismissibleAlert" color="warning" closeButton>
          <span v-for="err in serverErrors" :key="err">{{ err }}</span>
        </CAlert>
      </div>

      <div class="row">
        <div class="col-md-9 text-center actions">
          <input
            type="search"
            class="form-control"
            v-model="search"
            placeholder="Search by A/C Number or Meter No or Mobile No"
            @keyup.enter="searchMeter()"
            required
          />
        </div>
        <div class="col-md-3">
          <button type="button" class="btn btn-primary" @click="searchMeter()">
            Search
          </button>
        </div>
        <div class="col-md-12 mt-3" v-if="searchResult.account_number">
          <div class="row">
            <div class="col-md-4">
              <label>Customer Name</label>
              <input
                type="search"
                class="form-control"
                v-model="searchResult.customer_name"
                readonly
              />
            </div>
            <div class="col-md-4">
              <label>Account No.</label>
              <input
                type="search"
                class="form-control"
                v-model="searchResult.account_number"
                readonly
              />
            </div>
            <div class="col-md-4">
              <label>Meter No.</label>
              <input
                type="search"
                class="form-control"
                v-model="searchResult.hardware_serial_no"
                readonly
              />
            </div>
          </div>
          <hr />
        </div>
      </div>

      <validation-observer ref="formValidator">
        <form
          v-on:submit.prevent="addSupportTicket"
          v-if="searchResult.account_number"
        >
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Type</label>
                <select
                  name="type"
                  v-model="support.type"
                  class="form-control"
                  required
                >
                  <option value="">Select Support Type</option>
                  <option value="Leakage Support">Leakage Support</option>
                  <option value="Water Not Supplied">Water Not Supplied</option>
                  <option value="Others">Others</option>
                </select>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label>Channel</label>
                <select
                  name="channel"
                  v-model="support.channel"
                  class="form-control"
                  required
                >
                  <option value="">Select</option>
                  <option value="Phone">Phone</option>
                  <option value="Facebook">Facebook</option>
                  <option value="Others">Others</option>
                </select>
              </div>
            </div>

            <div class="col-md-6 col-12">
              <div class="form-group">
                <label>Description</label>
                <textarea class="form-control" v-model="support.description" required></textarea>
              </div>
            </div>

            <div class="col-md-6 col-12">
              <div class="form-group">
                <label>Attachment</label>
               <input
                type="file"
                id="file"
                ref="file"
                @change="handleFileUpload()"
              />
              </div>
            </div>

          </div>

          <button v-if="support.customer_account_id" type="submit" class="btn btn-primary">
            Save
          </button>
        </form>
      </validation-observer>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: "CreateTicket",
  data: () => {
    return {
      search: "",
      searchResult: {},
      support: {
        customer_id: "",
        customer_account_id: "",
        meter_id: "",
        type: "",
        channel: "",
        description: "",
      },
      file: "",
      serverErrors: [],
      showDismissibleAlert: false,
      isLoading: false,
    };
  },
  methods: {
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    searchMeter() {
      this.$store.dispatch("Settings/loading", true);
      return this.axios
        .post(`${process.env.VUE_APP_API_BASE_URL}/admin/meters/search`, {
          q: this.search,
        })
        .then(
          (res) => {
            this.searchResult = res.data.data;
            this.setSupportData(res.data.data);
            this.$store.dispatch("Settings/loading", false);
          },
          (error) => {
            this.$toastr.e("Meter Info not found.", "Failed!");
            this.$store.dispatch("Settings/loading", false);
            this.resetSupportData();
          }
        );
    },
    resetSupportData() {
      this.support = {
        customer_id: "",
        customer_account_id: "",
        meter_id: "",
        type: "",
        channel: "",
        description: "",
      };
      this.searchResult = {};
    },
    setSupportData(data) {
      let fields = ["customer_id", "customer_account_id", "id"];
      for (let key in fields) {
        if(fields[key] == 'id') {
          this.support['meter_id'] = data[fields[key]];
        } else {
          this.support[fields[key]] = data[fields[key]];
        }
        
      }
    },
    closeModal() {
      this.$emit("closeAddSupportModal");
    },   
    addSupportTicket() {
      try {
        let formData = new FormData();
        formData.append("attachment", this.file);
        for (const property in this.support) {
          formData.append(property, this.support[property]);
        }

        this.$store.dispatch("Settings/loading", true);
        this.$store
          .dispatch("CustomerSupport/store", formData)
          .then(
            () => {
              this.serverErrors = [];
              this.$store.dispatch("Settings/loading", false);
              this.$toastr.s(
                "New Ticket Successfuly Created!",
                "Ticket Created"
              );
              this.closeModal();
              this.resetSupportData();
            },
            (error) => {
              this.serverErrors = [];
              if (error.response.data) {
                this.serverErrors.push(error.response.data.message);
                // this.$refs.formValidator.setErrors(error.response.data.errors);
              }
              this.$toastr.e(error.response.data.message, "Failed!");
              this.$store.dispatch("Settings/loading", false);
            }
          );
      } catch (err) {
        console.log(err);
        this.isLoading = false;
      }
    },
  }, 
};
</script>

<style lang="scss" scoped>
.actions {
  button {
    margin: 10px;
    padding: 15px 20px;
  }
}
</style>
