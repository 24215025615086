<template>
  <div class="app">
    <CRow>
      <CCol sm="4">
        <h4 class="text-uppercase">Consumer Supports</h4>
      </CCol>

      <CCol sm="8"> </CCol>
    </CRow>

    <SupportWidgets />

    <CRow>
      <CCol sm="12">
        <CCard>
          <CRow>
            <CCol sm="10">
              <form v-on:submit.prevent="filterTickets(filter)">
                <CRow>
                  <CCol sm="2" class="pr-0">
                    <CInput
                      v-model="filter.hardware_serial_no"
                      placeholder="Meter No."
                    />
                  </CCol>
                  <CCol sm="2" class="pr-0">
                    <CInput
                      v-model="filter.mobile_number"
                      placeholder="Mobile No"
                    />
                  </CCol>
                  <CCol sm="2" class="pr-0">
                    <CInput
                      v-model="filter.account_number"
                      placeholder="Account Number"
                    />
                  </CCol>
                  <CCol sm="2">
                    <button class="btn btn-success w-100">Search</button>
                  </CCol>
                </CRow>
              </form>
            </CCol>
            <CCol sm="2" class="mb-3">
              <button
                v-if="
                  checkUsrPermissions(['CustomerSup_all', 'CustomerSup_create'])
                "
                @click="createSupportModal = true"
                class="btn btn-primary float-right"
              >
                + Create Ticket
              </button>
            </CCol>
            <CCol sm="12">
              <div class="table-responsive">
                <table class="table table-borderless">
                  <thead>
                    <tr>
                      <!-- <th scope="col">Ticket No.</th> -->
                      <th></th>
                      <th scope="col">SL</th>
                      <th scope="col">Consumer No</th>
                      <th scope="col">Consumer Name</th>
                      <th scope="col" class="text-center">Meter No</th>
                      <th scope="col" class="text-center">Type</th>
                      <!-- <th scope="col">Channel</th> -->
                      <th scope="col" class="text-center">Descrption</th>
                      <th scope="col" class="text-center" style="width: 90px">
                        Assigned
                      </th>
                      <th scope="col" class="text-center">Priority</th>
                      <th scope="col" class="text-center">Status</th>
                      <!-- <th scope="col">Attachment</th> -->
                      <!-- <th scope="col">Created At</th> -->
                      <th scope="col" class="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody v-if="customeSupports.length">
                    <tr v-for="(customeSupport, index) in customeSupports">
                      <!-- <td scope="row">{{ customeSupport.ticket_no }}</td> -->
                      <td scope="row">{{ meta.from + index }}</td>
                      <td
                        style="width: 50px; padding-top: 0; vertical-align: middle"
                      >
                        <img
                          v-if="customeSupport.status === 'RESOLVED'"
                          :src="getImgUrl('checkmark.png')"
                          alt="Checkmark"
                          style="max-height: 30px"
                        />
                      </td>
                      <td scope="row">
                        <router-link
                            v-if="customeSupport.customer_account"
                            :to="`/consumers/${customeSupport.customer_account.id}`"
                        >
                          {{ customeSupport.customer_account.account_number }}
                        </router-link>
                      </td>
                      <td scope="row">
                        {{ customeSupport.customer_account.first_name}}
                      </td>
                      <td scope="row" class="text-center">
                        <router-link
                          v-if="customeSupport.meter"
                          :to="`/meters/${customeSupport.meter.id}`"
                        >
                          {{ customeSupport.meter.hardware_serial_no }}
                        </router-link>
                        <span v-else>N/A</span>
                      </td>
                      <td scope="row" class="text-center">
                        {{ customeSupport.type }}
                      </td>
                      <!-- <td scope="row">{{ customeSupport.channel }}</td> -->
                      <td scope="row" class="text-center">
                        {{ formatDescription(customeSupport.description) }}
                      </td>
                      <td scope="row" class="text-center">
                        <div v-if="customeSupport.teams && customeSupport.teams.length">
                          <span
                            v-for="team in customeSupport.teams"
                            :key="team.id"
                            class="badge badge-primary mb-1"
                            style="font-size: 11px;"
                            >{{ team.name }}</span
                          >
                          &nbsp;
                        </div>
                        <a
                          v-else
                          href="javascript:void(0)"
                          @click="assignTeam(customeSupport)"
                          >Assign</a
                        >
                      </td>
                      <td scope="row">
                        <select
                          name="Priority"
                          v-model="customeSupport.priority"
                          class="form-control no-border"
                          required
                          style="width: auto; margin: 0 auto;"
                          @change="updatePriority(customeSupport)"
                        >
                          <option value="Low">Low</option>
                          <option value="Medium">Medium</option>
                          <option value="High">High</option>
                          <option value="Urgent">Urgent</option>
                        </select>
                      </td>
                      <td scope="row">
                        <select
                          name="status"
                          v-model="customeSupport.status"
                          class="form-control no-border"
                          required
                          @change="updateStatus(customeSupport)"
                          style="width: auto; margin: 0 auto;"
                        >
                          <option value="PENDING">Pending</option>
                          <option value="WORK-ONGOING">Work Ongoing</option>
                          <option value="COMPLETED">Completed</option>
                          <option value="RESOLVED">Resolved</option>
                          <option value="ON-HOLD">On Hold</option>
                        </select>
                      </td>
                      <!-- <td scope="row">
                        <a v-if="customeSupport.attachment_url" class="btn btn-primary btn-sm" target="_blank" :href="customeSupport.attachment_url" download>Download</a>
                        <span v-else>N/A</span>
                      </td> -->
                      <!-- <td scope="row">{{ dateFormat(customeSupport.created_at) }}</td> -->
                      <td scope="row" class="text-center">
                        <span
                          v-if="
                            checkUsrPermissions([
                              'CustomerSup_all',
                              'CustomerSup_show',
                            ])
                          "
                          class="mb-1"
                          shape="pill"
                          @click="
                            $router.push(
                              '/customer-supports/' + customeSupport.id
                            )
                          "
                        >
                          <i class="fa fa-eye fa-2x color-view"></i>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="9">
                        <h5 class="text-center">Data Not Available.</h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CCol>
          </CRow>
          <CPagination
            v-if="customeSupports.length"
            align="center"
            :pages="rows"
            :active-page.sync="filter.currentPage"
            @update:activePage="handlePageChange"
          />
        </CCard>
      </CCol>
    </CRow>

    <CModal
      :closeOnBackdrop="false"
      size="lg"
      title="Create Support Ticket"
      color="primary"
      class="hide-footer custom-close-button"
      :show.sync="createSupportModal"
    >
      <CreateTicket
        v-if="createSupportModal"
        @closeAddSupportModal="closeAddSupportModal"
      ></CreateTicket>
    </CModal>

    <CModal
      :closeOnBackdrop="false"
      size=""
      title="Assign Team"
      color="primary"
      class="hide-footer custom-close-button"
      :show.sync="showAssignModal"
    >
      <AssignSupport
        v-if="showAssignModal"
        @closeAssignSupportModal="closeAssignSupportModal"
        :support="selectedSupport"
      ></AssignSupport>
    </CModal>

    <!-- <CModal
      :closeOnBackdrop="false"
      size=""
      title="Ticket Status Update"
      color="primary"
      class="hide-footer custom-close-button"
      :show.sync="showStatusModal"
    >
      <TicketStatusUpdate
        v-if="showStatusModal"
        @statusUpdated="closeStatusModal"
        :support="selectedSupport"
      ></TicketStatusUpdate>
    </CModal> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CreateTicket from "./CreateTicket";
import SupportWidgets from "./partials/SupportWidgets";
import AssignSupport from "./partials/AssignSupport";
// import TicketStatusUpdate from "./TicketStatusUpdate";
export default {
  name: "CustomerSupports",
  data: () => {
    return {
      serverErrors: [],
      showDismissibleAlert: false,
      createSupportModal: false,
      showAssignModal: false,
      selectedSupport: {},
      filter: {
        currentPage: 1,
        hardware_serial_no: "",
        account_number: "",
        mobile_number: "",
      },
    };
  },
  components: {
    CreateTicket,
    SupportWidgets,
    AssignSupport,
  },
  methods: {
    getImgUrl(pic) {
      return require("../../assets/images/" + pic);
    },
    statusModal(support) {
      this.selectedSupport = support;
      this.showStatusModal = true;
    },
    handlePageChange(value) {
      this.filter.currentPage = value;
      this.$router.replace({
        name: "Customer Supports",
        query: this.filter,
      });
      this.$store.dispatch("CustomerSupport/getCustomerSupports", this.filter);
    },
    closeAddSupportModal() {
      this.createSupportModal = false;
    },
    updateStatus(support) {
      this.$store.dispatch("CustomerSupport/statusUpdate", support).then(
        () => {
          this.$toastr.s(
            "Ticket Status Successfuly Created!",
            "Status Updated"
          );
        },
        (error) => {
          this.$toastr.e(error.response.data.message, "Failed!");
        }
      );
    },
    updatePriority(support) {
      this.$store.dispatch("CustomerSupport/priorityUpdate", support).then(
        () => {
          this.$toastr.s(
            "Ticket Priority Successfuly Created!",
            "Priority Updated"
          );
        },
        (error) => {
          this.$toastr.e(error.response.data.message, "Failed!");
        }
      );
    },
    formatDescription(desp) {
      if (desp && desp.length > 30) {
        return desp.substring(0, 30) + "...";
      }
      return desp;
    },
    filterTickets(search) {
      if (search) {
        search.currentPage = 1;
        this.$router.replace({
          name: "Customer Supports",
          query: this.filter,
        });
        this.$store.dispatch("CustomerSupport/getCustomerSupports", search);
      }
    },
    assignTeam(support) {
      this.selectedSupport = support;
      this.showAssignModal = true;
    },
    closeAssignSupportModal() {
      this.showAssignModal = false;
    },
    setSearchParams() {
      this.filter.hardware_serial_no = this.$route.query.hardware_serial_no
        ? this.$route.query.hardware_serial_no
        : "";
      this.filter.mobile_number = this.$route.query.mobile_number
        ? this.$route.query.mobile_number
        : "";
      this.filter.account_number = this.$route.query.account_number
        ? this.$route.query.account_number
        : "";
      this.filter.currentPage = this.$route.query.currentPage
        ? parseInt(this.$route.query.currentPage)
        : 1;
    },
  },
  computed: {
    ...mapGetters("CustomerSupport", ["customeSupports", "rows", "perPage", "meta"]),
  },
  mounted() {
    this.setSearchParams();
    this.$store.dispatch("CustomerSupport/getCustomerSupports", this.filter);
  },
};
</script>

<style scoped>
.custom-button {
  color: #000;
  padding: 10px 8px 10px 15px;
  font-size: 15px;
  border-bottom: 1px solid rgba(204, 204, 204, 0.4);
  line-height: 1;
}

.card {
    border: none;
    padding: 15px 15px 0;
}
tbody:before {
  content: "@";
  display: block;
  line-height: 30px;
  text-indent: -99999px;
}
.hide-footer >>> footer {
  display: none;
}
.custom-close-button >>> .close {
  color: aliceblue;
}
</style>

<style lang="scss" scoped>
.form-check-input {
  width: 18px;
  height: 18px;
}
.no-border {
  border: none;
  &:focus {
    box-shadow: none;
  }
}
div {
  h4 {
    font-size: 15px;
    font-weight: bold !important;
    color: #000;
  }
  .table {
    font-size: 14px;

    thead {
      tr {
        th {
          background: #f8f9fa;
          color: #000;
        }
      }
    }
    tbody {
      font-size: 13px;
      tr {
        color: #000;
        td {
          padding: 0.75rem 0 0.75rem 0.75rem;
          button {
            font-size: 13px;
          }
        }
        td:nth-child(2) {
          color: #5e76e7;
        }
        td:nth-child(2) {
          font-weight: 500 !important;
        }
      }
    }
  }
}
</style>
